/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

#modal-budget .table-FullBudget {
  position: relative; }
  #modal-budget .table-FullBudget thead th {
    position: sticky;
    z-index: 1000;
    top: 0; }

.table-FullBudget {
  position: relative; }
  .table-FullBudget thead th {
    position: sticky;
    z-index: 1000;
    top: -22px; }
  .table-FullBudget tr.row-Header i.fa {
    margin-right: 5px;
    color: #5378ad; }
  .table-FullBudget tr.row-Header td:first-child > div {
    font-weight: 600;
    padding-left: 20px; }
  .table-FullBudget tr.row-Header:first-child td:first-child > div {
    padding-left: 0; }
  .table-FullBudget tr.row-Header:hover {
    cursor: pointer;
    background-color: #e8edf4; }
  .table-FullBudget tr:not(.row-Header) td {
    padding: 0 !important;
    border-top: none !important; }
  .table-FullBudget tr:not(.row-Header) td > div {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .table-FullBudget tr:not(.row-Header).expanded td > div {
    max-height: 300px;
    padding: 8px;
    border-top: 1px solid #e7eaec; }
  .table-FullBudget tbody tr:not(.row-Header) td:first-child > div {
    padding-left: 42px !important; }
