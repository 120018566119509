/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

.app-header .navbar-nav .nav-item {
  margin-left: 1rem !important;
  min-width: 0; }
  .app-header .navbar-nav .nav-item .btn {
    padding: 0; }
    .app-header .navbar-nav .nav-item .btn:hover {
      color: #5378ad; }
  .app-header .navbar-nav .nav-item .btn-link {
    color: #5b7083; }

.app-header .btn .badge {
  top: -2px;
  right: 12px; }
