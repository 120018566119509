/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

.notifications-list ul {
  margin: 0;
  padding: 0; }

.notifications-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d2d7df; }

.notifications-list time {
  margin: 15px 25px 0 0;
  font-size: 0.75rem;
  font-style: italic;
  text-align: right; }

.notifications-list time .date {
  display: block; }
