.textbox {
  font-size: small;
  border: 1px solid black;
  height: 16px;
}
.label {
  font-size: small;
}
.labelbtn {
  font-size: 12px;
  color: Maroon;
  font-weight: bolder;
}
.labelRefDocHeader {
  font-weight: bold;
  font-size:20px;
  margin-left: auto;
  margin-right: auto;
  width: 24em;
}
.labelred {
  font-size: 12px;
  font-weight: bold;
  background-color: Red;
  color: white;
}
.labelbold {
  font-size: 10px;
  color: Black;
  font-weight: bolder;
}
.labelhidden {
  color: white;
  width: 0px;
  height: 0px;
}
.labelminor  {
  font-size: 12px;
  color: Maroon;
}
.labelh2 {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
}
.labelh1 {
  font-size: 16px;
  color: maroon;
  font-weight: bolder;
}
.dropdownmenu {
  font-size: x-small;
  background-color: #dddddd;
}

.GridHeaderLeft
{
  font-weight: bold;
  font-size: x-small;
  background-color:  Maroon;
  color: white;
  text-align: left;
  height: 12px;
}
.GridHeaderCenter
{
  font-weight: bold;
  font-size: x-small;
  background-color:  Maroon;
  color: white;
  text-align: center;
  height: 14px;
}
.GridHeader
{
  font-weight: bold;
  font-size: x-small;
  background-color:  Maroon;
  color: white;
  text-align: right;
  height: 12px;
}
.GridHeader a
{
  background-color:  Silver;
  color: red;
  padding: 10px 15px 10px 15px;
  text-align: right;
}
.GridHeader a:hover
{
  background-color: White;
  /*// background-image: url(images/spin.gif);*/
  background-repeat:no-repeat;
  background-position: 0 100%;
  text-align: right;
}
.GridFooter
{
  background-color:    Silver;
  font-size: x-small;
  height: 15px;
}
.GridItem
{
  background-color: white;
  font-size:   x-small;
  height: 20px;
  text-align: left;
}
.GridAltItem
{
  background-color:  silver;
  font-size: x-small;
  height: 20px;
}
.GridSelectedItem
{
  background-color: maroon;
  font-size:   x-small;
  height: 20px;
}
.Animation2
{
  display:none;
  position:absolute;
  width:1px;
  height:1px;
  left: 260px;
  top:172px;
  padding:3px;
  border:solid 1px #FFF;
}
.aspimage {
  border-style:dashed;
  border: 0px;
  richness:100;
}
#container {
  width: 1000px;
  height: 738px;
  margin: 0px 2px 2px 0px;
  border: groove;
  background-color: #FFF;
}
#matting {
  width: 1008px;
  height: 746px;
  margin: 0 auto;
  border: 4px outset Maroon;
  background-color:  Maroon;
}
#messagecenter {
  width: 1000px;
  height: 12px;

}
#headerleft {
  float: left;
  height: 135px;
  background-color: Silver;
  width: 160px;
}
#headerarea {
  float: left;
  height: 135px;
  width: 680px;
  background-color: Silver;
}
#headerright {
  float: left;
  height: 135px;
  background-color: Silver;
  width: 160px;
}
#bodyarea {
  float:right;
  margin-left: 0px;
  height: 433px;
  width: 1000px;
  padding-top: 10px;
}
#bodyleft {
  float: left;
  width: 160px;
  height: 428px;
  border-style:  groove groove groove none;
}
#chatarea {
  float: right;
  height: 0px;
  width: 999px;
  border-style: groove none none none;
  text-align: left;
  margin: 0 0 0 0;
  background-color: silver;
}
#footerarea {
  float: right;
  width: 999px;
  height: 60px;
  text-align: left;
  margin: 0 0 0 0;
  border-style: groove none none none;
  background-color:  #ddeeff;
}

.Panel {
  border-style: none none none none;
  background: white;
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}
.buttonsmall {
  width: 50px;
  font-size: x-small;
}
.Layer1 /* used in resume doc */
{
  position:relative;
  left:31px;
  top:18px;
  width:300px;
  height:28px;
  z-index:1;
  background-color: #FFCC66;
  /*layer-background-color: #FFCC66; <- original to the resume document*/
  border: 1px none #000000;
}

.Layer2  /* used in resume doc */
{
  position:relative;
  left:257px;
  top:94px;
  width:237px;
  height:24px;
  z-index:2;
}
.resTable
{
  width:350px;
  /*border-width:1px;
  border-spacing: 2px;
  border-style:outset;
  border-color: Gray;
  border-collapse: separate;*/
  border:0px;
  background-color: Transparent;
}
.resTDRight
{
  width: 40%;
  /*border-width: 1px;
  padding: 1px;
  border-style:inset;
  border-color:Gray;
  background-color:Transparent;*/
  text-align:left;
  font-size:small;
  font-style:normal;
  font-weight:bold;
  vertical-align:middle;
}
.resTDLeft
{
  width:400px;
  /*border-width: 1px;
  padding: 1px;
  border-style:inset;
  border-color:Gray;
  background-color:Transparent;*/
  text-align:right;
  font-size:small;
  font-style:normal;
  font-weight:bold;
  vertical-align:middle;
}

.perf1div /* used in resume doc */
{
  position:relative;
  left:24px;
  /*top:141px; */
  width:358px;
  height:24px;
  z-index:3;

}

.perf2div /* used in resume doc */
{
  position:relative;
  left:25px;
  /*top:201px; */
  width:358px;
  height:24px;
  z-index:3;
}
.perf3div /*used in resume doc */
{
  position:relative;
  left:25px;
  /*top:171px; */
  width:358px;
  height:24px;
  z-index:3;
}

.perf4div /* used in resume doc */
{
  position:relative;
  left:24px;
  /*	top:231px; */
  width:358px;
  height:24px;
  z-index:3;
}
.perf5div /* used in resume doc */
{
  position:relative;
  left:16px;
  /*	top:261px; */
  width:363px;
  height:24px;
  z-index:3;
}
.perf6div /* used in resume doc */
{
  position:relative;
  left:21px;
  /*	top:291px; */
  width:358px;
  height:24px;
  z-index:3;
}
.perf7div /* used in resume doc */
{
  position:relative;
  left:24px;
  /*top:321px; */
  width:357px;
  height:24px;
  z-index:3;
}
.resProfileText
{
  text-align:justify;
  font-size:small;
  font-style:normal;
  font-weight:bold;
  vertical-align:middle;
  width: 85px;
}
.resProfileHeaderText
{
  text-align:center;
  left: 0;
  font-size:large;
  font-style:normal;
  font-weight:bold;
  vertical-align:middle;
}
.resProfileSubHeaderText
{
  text-align:center;
  font-size:medium;
  font-style:normal;
  margin-top: '20px';
  margin-bottom: '20px';
  font-weight:bold;
  vertical-align:middle;
}
.observationText
{
  margin:10px;
  text-align:justify;
  font-size:small;
  font-style:normal;
  font-weight:normal;
  vertical-align:middle;

}

.Layer6 /*used in resume doc */
{
  position:relative;
  left:31px;
  top:46px;
  width:300px;
  height:24px;
  z-index:6;
  background-color: #FFCC99;
  /*layer-background-color: #FFCC99;  original to the resume document */
  right: 1054px;
}

.nameLabel { /* used in resume doc */
  /*padding-top:6px;*/
  z-index:6;
  background-color: transparent;
  border-style:none;
  border-width:0;
  border-color:#FFFFFF;
  text-align:center;
  font-size:x-large;
  font-weight:bold;
  border-right: gray 1px;
  border-top:gray 1px;
  overflow:visible;
  border-left:gray 1px;
  border-bottom:gray 1px;
  /*height:auto;*/
}
.titleLabel
{
  padding-top:6px;
  z-index:6;
  background-color: transparent;
  border-style:none;
  border-width:0;
  border-color:#FFFFFF;
  text-align:center;
  font-size:x-large;
  font-weight:bold;
  font-style:italic;
  border-right: gray 1px;
  border-top:gray 1px;
  overflow:visible;
  border-left:gray 1px;
  border-bottom:gray 1px;
  height:auto;
}
.resumeImage
{
  padding-right:2px;
  padding-bottom:2px;
  float:left;
  border-style:inset;
}


div#rpheading,div#rpcontainer,div#rpfooter
{
  border: None;
  max-width: 650px;
  min-width: 600px;
}

div#rpcontainer {
  position: relative;
  margin: 0 auto;
}
div#rpheading {
  margin: 10px auto -1px auto;
}
div#rpheading h1 {
  margin: 5px;
}
div#rpleft {
  position: relative;
  float:left;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  background: transparent;
  padding: 5px;
  border-right: none;
}
div#rpcontent {
  margin-left: 210px;
  background: transparent;
  border: none;
  padding: 10px;
}
div#rpfooter {
  margin: -1px auto 10px auto;
}
div#rpfooter p {
  margin: 5px;
}

#resPdivLeftTop
{
  float:left;
  width:70%;
}
#resPdivLeftBottom
{
  position: relative;
  width: 70%;
  clear: both;
  margin-top: 300px;
}
#resPdivRight
{
  margin-left:75%;
  width:25%;
  top:200px;
  text-align:left;
}

Div.RefDocHeader {
  font-weight: bold;
  font-size:20px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}
Div.RefDocBody {
  font-weight: normal;
  font-size:14px;
  margin-left: auto ;
  margin-right: auto ;
  width: 35em;
  text-align: justify;
}
Div.RefDocBodyGrid {
  font-weight: normal;
  margin-left: auto ;
  padding-left:  50px;
  margin-right: auto ;
  width: 200em;
  text-align: justify;
}
Div.RefDocFooter {
  width: 700px ;
  font-weight: normal;
  font-size:8px;
  margin-left: auto ;
  margin-right: auto ;
  text-align: left;
}
table.RefDocTable
{
  border-width: 3px;
  border-spacing : 2px;
  border-color: Black;
  border-style: double;
  width: 600px;
  margin-right:15px
}
td.refdoctdName
{
  border-width: 1px;
  border-spacing : 1px;
  border-color: Gray;
  border-style: solid;
  background-color:#b0e0e6;
  text-align:left;
  font-weight:bold;
  font-size:larger;
}
td.refdoctdDesc
{
  border-width: 1px;
  border-spacing : 1px;
  border-color: Gray;
  border-style: solid;
  background-color:White;
  text-align:left;
  font-size:large;
}
td.LJ
{
  border-width: 1px;
  border-spacing : 1px;
  border-color: Gray;
  border-style: solid;
  background-color:#F4A460;
  text-align:left;
}

P.bold
{
  font-weight:bold;
}
P.underline
{
  text-decoration:underline;
}
ul.disc
{
  list-style-type:disc;
}
.GroupBox
{
  border: 1px solid #0000FF;
  display: inline;
  padding: 0px;
  border-style:inset;
  float:left;
}

.GroupBoxLegend
{
  padding-bottom: 0px;
  font-size:smaller;
  font-weight:bold;
  text-align:left;
  float:left;
}
.riskGridGroupBox
{
  border: 0px;
  display: inline;
  padding: 0px;
  border-style: none;
  float:left;
}
.GroupPanel
{
  border-style:inset;
}
.TD22p5
{
  width:22.5pt;
  padding:.75pt .75pt .75pt .75pt;
}
.TD303
{
  width:303.0pt;
  padding:.75pt .75pt .75pt .75pt;
}
.WBSHeader
{
  width:auto;
  font-weight: bold;
  font-size:16px;
  margin-right: auto;
  text-align: left;
}
.WBSTable
{
  width:600px;
  margin-right:15px;
  text-align:center;
}
.WBSGroupHeader
{
  font-weight: bold;
  text-align: left;
}
.WBSItem
{
  font-weight: normal;
  text-align: left;
  margin-left: 10px;
}
.WBSTask {
  font-weight: normal;
  text-align: left;
  margin-left: 15px;
}
.centeredImage
{
  text-align:center;
  margin-top:0px;
  margin-bottom:0px;
  padding:0px;
}


.RefDoc,
.RefDocBody {
  display: flex;
  flex-direction: column;
  /*border: 1px solid black;*/
}

.RefDocNav {
  order: -1;
  border-right: 1px solid black;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .RefDocBody {
    flex-direction: row;
    flex: 1;
  }
  .RefDocContent {
    flex: 1;
  }
  .RefDocNav, .RefDocAds {
    /* 12em is the width of the columns */
    flex: 0 0 20em;
  }
}
