/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

.resource-Item.resource-Item--full {
  display: block; }
  .resource-Item.resource-Item--full .resource-Row {
    border-bottom: 1px solid #d2d7df;
    margin-bottom: 20px;
    padding-bottom: 20px; }
  .resource-Item.resource-Item--full .resource-Row h4 {
    margin-top: 0;
    margin-bottom: 15px; }
  .resource-Item.resource-Item--full .resource-Info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .resource-Item.resource-Item--full .resource-Info h4 {
      margin: 0; }
    .resource-Item.resource-Item--full .resource-Info h4 small {
      display: block;
      margin-top: 10px; }
  .resource-Item.resource-Item--full .resource-Profile {
    width: 75px;
    height: 75px; }

/**
 * PerformanceTable
 */
.performance-Row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  min-height: 36px; }
  .performance-Row .performance-Row_Description {
    -ms-flex-preferred-size: 125px;
    flex-basis: 125px;
    font-size: 10.4px; }
    .performance-Row .performance-Row_Description:first-child {
      text-align: right; }
  .performance-Row .performance-Row_Blocks {
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 5px; }
  .performance-Row .performance-Row_Block {
    background-color: #d2d7df;
    margin: 0 2px;
    width: 15px;
    height: 15px; }
    .performance-Row .performance-Row_Block i.fa {
      position: relative;
      top: -2px; }
