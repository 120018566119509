/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

.component-CheckLog {
  font-size: 0.85rem; }
  .component-CheckLog li h4 {
    font-size: 1.25rem; }
  .component-CheckLog .check-List {
    list-style: none;
    padding-left: 20px;
    margin-bottom: 10px;
    max-height: none !important; }
    .component-CheckLog .check-List > li:first-child {
      margin-top: 0; }
    .component-CheckLog .check-List a.btn-outline-secondary {
      color: #333; }
    .component-CheckLog .check-List ul.results-list > li > p {
      margin-left: 20px; }
    .component-CheckLog .check-List li:before {
      content: "";
      font-family: "fontAwesome";
      position: absolute;
      left: 0; }
    .component-CheckLog .check-List li.check-passed {
      font-weight: 600; }
      .component-CheckLog .check-List li.check-passed:before {
        content: "\f00c"; }
    .component-CheckLog .check-List li.check-failed {
      font-weight: 600; }
      .component-CheckLog .check-List li.check-failed:before {
        content: "\f00d"; }
    .component-CheckLog .check-List li.check-warning {
      font-weight: 600; }
      .component-CheckLog .check-List li.check-warning:before {
        content: "\f071"; }
    .component-CheckLog .check-List > ul {
      -webkit-column-count: 2;
      column-count: 2; }
    .component-CheckLog .check-List > ul > li {
      margin-top: 1rem;
      border-top: 1px solid #f3f3f4;
      padding: 1.5rem 1rem 1rem; }
      .component-CheckLog .check-List > ul > li:first-child {
        margin-top: 0;
        border-top: none;
        padding-top: 0; }
    .component-CheckLog .check-List ul {
      list-style: none;
      padding: 0; }
    .component-CheckLog .check-List > ul > li > ul > li {
      display: block;
      position: relative; }
      .component-CheckLog .check-List > ul > li > ul > li p {
        padding-left: 30px; }
        .component-CheckLog .check-List > ul > li > ul > li p:last-child {
          margin-bottom: 0; }
      .component-CheckLog .check-List > ul > li > ul > li a.btn {
        margin-bottom: 0; }
  .component-CheckLog .check-passed {
    color: #49c3a2; }
  .component-CheckLog .check-failed {
    color: #9e3237; }
  .component-CheckLog .check-warning {
    color: #f8ac59; }
  .component-CheckLog ul ul li {
    font-weight: 400; }
  .component-CheckLog .card-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 20px 20px 20px; }
    .component-CheckLog .card-footer p {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 2rem; }
      .component-CheckLog .card-footer p strong {
        margin-left: 1rem; }
    .component-CheckLog .card-footer .btn {
      margin-bottom: 0; }
