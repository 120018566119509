.table .min300 {
  min-width: 300px; }

.row-InteractionSchedule {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .row-InteractionSchedule .day {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  @media (min-width: 1200px) {
    .row-InteractionSchedule .col-xl-2 {
      flex: 20%;
      max-width: 20%; } }
  @media (max-width: 992px) {
    .row-InteractionSchedule {
      margin-top: 2rem; } }
  .row-InteractionSchedule .component-Box .form-inline .form-group {
    margin-right: 0; }
  .row-InteractionSchedule .card-body {
    min-height: 250px;
    padding: 1.25rem 1rem; }
  .row-InteractionSchedule .btn i.fa-plus {
    margin-left: 0; }

.stakeholder-plan-4-display {
  width: 230px !important; }
