.route-Register .container .col:first-child .card {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  font-size: 0.75rem; }
  .route-Register .container .col:first-child .card p {
    text-align: left; }
  .route-Register .container .col:first-child .card .card-body {
    padding: 0; }

.route-Register .container .card-body {
  max-height: none; }

.route-Register .container img.logo {
  max-width: 200px; }

.route-Register .container h4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.route-Register .container .col:last-child .card-body {
  padding: 2rem; }

.route-Register .container .form-group {
  display: flex;
  flex-wrap: wrap; }
  .route-Register .container .form-group .form-control {
    width: auto;
    flex-grow: 1;
    max-width: 100%; }
    .route-Register .container .form-group .form-control.av-valid {
      border-color: #28a745; }
  .route-Register .container .form-group select.form-control {
    width: 100%; }
  .route-Register .container .form-group .invalid-feedback {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    margin-bottom: 0; }
  .route-Register .container .form-group[color="danger"] input[type="text"],
  .route-Register .container .form-group[color="danger"] input[type="password"],
  .route-Register .container .form-group[color="danger"] input[type="email"] {
    border-color: #dc3545; }

.route-Register .container .password {
  display: block;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: #727272; }
  .route-Register .container .password__input {
    display: block;
    text-transform: none;
    width: 100%;
    height: 42px;
    font-weight: 400;
    color: #212121; }
    .route-Register .container .password__input:focus, .route-Register .container .password__input:active {
      outline: 0; }
  .route-Register .container .password__show {
    cursor: pointer;
    position: absolute;
    right: 0;
    background: transparent;
    color: #353535;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.8em;
    z-index: 200; }
  .route-Register .container .password__strength {
    position: absolute;
    width: 0%;
    height: 4px;
    bottom: -8px;
    left: 0;
    background: transparent;
    transition: all 300ms ease-in-out; }
    .route-Register .container .password__strength[data-score="null"] {
      width: 0;
      background: red; }
    .route-Register .container .password__strength[data-score="0"] {
      width: 5%;
      background: #f44336; }
    .route-Register .container .password__strength[data-score="1"] {
      width: 25%;
      background: #f44336; }
    .route-Register .container .password__strength[data-score="2"] {
      width: 50%;
      background: #ffeb3b; }
    .route-Register .container .password__strength[data-score="3"] {
      width: 75%;
      background: #4caf50; }
    .route-Register .container .password__strength[data-score="4"] {
      width: 100%;
      background: #4caf50; }

.route-Register .container .input-group {
  align-items: center; }
