/**
* Hotfixes for Styles
*
* This second stylesheet is for hotfixes/vanilla CSS.
* Do not edit the main style.css directly as its compiled from style.scss, rather
* add your changes here if you are not compiling the Sass files.
*/
.activeRoute {
  font-weight: bold;
  text-decoration: underline;
}

.selectLabelEnabled {
  font-weight: bold;
  color: #000000;
}

.selectLabelDisabled {
  font-weight: bold;
  color: #d3d3d3;
}

.RefDoc,
.RefDocBody {
  display: flex;
  flex-direction: column;
  /*border: 1px solid black;*/
}

.RefDocNav {
  order: -1;
  border-right: 1px solid black;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .RefDocBody {
    flex-direction: row;
    flex: 1;
  }
  .RefDocContent {
    flex: 1;
  }
  .RefDocNav,
  .RefDocAds {
    /* 12em is the width of the columns */
    flex: 0 0 20em;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.react-datepicker__input-container {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #c2cfd6;
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__input-container input {
  font-size: 0.875rem;
  color: #495057;
  border: none;
  outline: none;
}

.customDatePickerWidth50,
.customDatePickerWidth50 > div.react-datepicker-wrapper,
.customDatePickerWidth50
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth50
  > div
  > div.react-datepicker__input-container
  input {
  width: 70%;
  align-items: flex-end;
}

.react-datepicker__input-container {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #c2cfd6;
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__input-container input {
  font-size: 0.875rem;
  color: #495057;
  border: none;
  outline: none;
}

.tool-tip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tool-tip .tool-tiptext {
  visibility: hidden;
  width: auto;
  color: black;
  text-align: center;
  border-radius: 4px;
  padding: 6px;
  position: absolute;
  z-index: 1;
  font-weight: bold;
  background-color: white;
  border: 1px solid #c2cfd6;
  text-transform: capitalize;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%);
}

.tool-tip:hover .tool-tiptext {
  visibility: visible;
}
.table tbody:not(#budget-report) > tr > td {
  vertical-align: middle; 
  word-break: break-word;
  /* white-space: break-spaces; */
}
