@media (min-width: 1200px) {
  .row-StatusReport .col-lg-2 {
    width: 20%;
    max-width: 20%;
    flex: 0 0 20%; } }

.row-StatusReport .component-Box {
  border: 1px solid #e7eaec;
  border-top: 0; }

.row-StatusReport .report-List {
  list-style: none;
  padding-left: 0;
  margin-right: 0; }

.row-StatusReport .report-Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px; }
  .row-StatusReport .report-Item i.fa {
    font-size: 1rem;
    padding-top: 3px;
    margin-right: 10px; }
  .row-StatusReport .report-Item h3 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 5px; }
  .row-StatusReport .report-Item h3 small {
    display: block;
    font-style: italic;
    margin-top: 5px;
    font-size: 15px; }
  .row-StatusReport .report-Item p,
  .row-StatusReport .report-Item span {
    font-size: 15px;
    margin-bottom: 5px; }

.component-Alert {
  display: flex; }
  .component-Alert .alert-Resource {
    width: 100px;
    height: 100px;
    display: block;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 2rem; }
    .component-Alert .alert-Resource img {
      width: 100%;
      height: auto;
      display: block; }
  .component-Alert .alert-Body {
    flex: 1; }
  .component-Alert .alert-Response .form-group {
    margin-top: 1rem; }
  .component-Alert .alert-Response input {
    margin-right: 0.5rem;
    cursor: pointer; }
  .component-Alert .alert-Response label {
    cursor: pointer; }

.btn-week-continue {
  margin-top: 20px; }
