/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

.table.table-Gantt td.col-fix--first {
  font-size: 0.75rem;
  min-height: 40px; }
  .table.table-Gantt td.col-fix--first .btn {
    padding: 0;
    font-size: 0.75rem;
    font-weight: 600; }

.table.table-Gantt .gantt-Bar {
  min-width: 17px;
  height: 40px;
  padding: 0.5rem 0;
  position: relative; }
  .table.table-Gantt .gantt-Bar:nth-child(5n + 6) {
    border-right: 1px solid #e7eaec; }
  .table.table-Gantt .gantt-Bar:last-child {
    border-right: 0; }

.table.table-Gantt .gantt-PlannedDate_Bar {
  background-color: #eec8ca;
  border-top: 2px dashed #9e3237;
  border-bottom: 2px dashed #9e3237;
  display: block;
  height: 100%; }

.table.table-Gantt .gantt-CurrentPosition:before {
  content: "";
  position: absolute;
  border-left: 2px dashed #5b7083;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 2; }

.table.table-Gantt .gantt-Complete .gantt-ActualDate_Bar {
  background-color: #9e3237; }

.table.table-Gantt .gantt-nonCritical .gantt-PlannedDate_Bar {
  background-color: #eef2f7;
  border-color: #5378ad; }

.table.table-Gantt .gantt-nonCritical .gantt-ActualDate_Bar {
  border-color: #5378ad; }

.table.table-Gantt .gantt-nonCritical.gantt-Complete .gantt-ActualDate_Bar {
  background-color: #5378ad; }

.table.table-Gantt .gantt-PlannedDate:first-child .gantt-PlannedDate_Bar,
.table.table-Gantt :not(.gantt-PlannedDate) + .gantt-PlannedDate .gantt-PlannedDate_Bar {
  border-left: 2px dashed #9e3237; }

.table.table-Gantt .gantt-PlannedDate:first-child.gantt-nonCritical .gantt-PlannedDate_Bar,
.table.table-Gantt :not(.gantt-PlannedDate) + .gantt-PlannedDate.gantt-nonCritical .gantt-PlannedDate_Bar {
  border-left: 2px dashed #5378ad; }

.table.table-Gantt .gantt-ActualDate_Bar {
  height: 33%;
  display: block;
  position: absolute;
  top: 33%;
  width: 100%;
  border-top: 2px solid #9e3237;
  border-bottom: 2px solid #9e3237;
  background-color: white;
  z-index: 1; }

.table.table-Gantt .gantt-ActualDate:first-child .gantt-ActualDate_Bar,
.table.table-Gantt :not(.gantt-ActualDate) + .gantt-ActualDate .gantt-ActualDate_Bar {
  border-left: 2px solid #9e3237; }

.table.table-Gantt .gantt-ActualDate:first-child.gantt-nonCritical .gantt-ActualDate_Bar,
.table.table-Gantt :not(.gantt-ActualDate) + .gantt-ActualDate.gantt-nonCritical .gantt-ActualDate_Bar {
  border-left: 2px solid #5378ad; }

.table.table-Gantt .gantt-ActualDate:not(:last-child):last-of-type .gantt-ActualDate_Bar {
  border-right: 2px solid orange; }

.table.table-Gantt .gantt-Last .gantt-ActualDate_Bar {
  border-right: 2px solid #9e3237; }

.table.table-Gantt .gantt-Last.gantt-nonCritical .gantt-ActualDate_Bar {
  border-right-color: #5378ad; }

.table.table-Gantt .gantt-Last .gantt-PlannedDate_Bar {
  border-right: 2px dashed #9e3237; }

.table.table-Gantt .gantt-Last.gantt-nonCritical .gantt-PlannedDate_Bar {
  border-right-color: #5378ad; }

.table.table-Gantt .gantt-Float:after {
  content: "";
  border-bottom: 2px solid #5378ad;
  position: absolute;
  top: 18px;
  width: 100%; }

.table.table-Gantt .gantt-Float-last:after {
  content: "";
  border-bottom: 2px solid #5378ad;
  position: absolute;
  top: 18px;
  width: 100%; }

.table.table-Gantt .gantt-Float-last:before {
  content: "";
  border-right: 2px solid #5378ad;
  position: absolute;
  right: 0;
  top: 10px;
  height: 20px; }
