.component-Box .form-inline {
  align-items: flex-end; }

.component-Box .form-inline .form-group {
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-right: 40px; }

.component-Box .form-inline .form-control {
  display: block;
  width: 100%; }
