.component-Classroom .card-body > div > div:first-child {
  margin-bottom: 50px;
  display: flex; }

.component-Classroom h4 {
  margin-right: 50px; }

.component-Classroom h4 small {
  display: block;
  margin-top: 15px; }
