/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

.modal .modal-medium.task-modal-medium {
  max-width: 800px; }

.modal .modal-header {
  flex-direction: row; }

.modal .modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .modal .modal-title .btn {
    font-size: 1.25rem;
    color: #5B7083; }

.modal .modal-dialog.modal-wide {
  max-width: 1000px; }

.modal .modal-dialog.modal-1100 {
  max-width: 1100px;
  min-height: 600px; }

.modal-Email .mail-box-header {
  border: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 0 15px; }

.modal-Email ul.folder-list,
.modal-Email ul.category-list {
  padding: 0;
  margin-bottom: 25px; }

.modal-Email .table {
  margin-bottom: 0; }

.modal-Email .fa-circle.blue {
  color: #5378ad; }

.modal-Email .fa-circle.teal {
  color: #49c3a2; }

.modal-Email .label-blue {
  background-color: #5378ad;
  color: white; }

.modal-Email .label-teal {
  background-color: #49c3a2;
  color: white; }

.modal-Email .label {
  font-size: 80%; }

.modal-Email table.table-mail tr td {
  padding: 12px; }

.modal-Email .table-mail .check-mail {
  padding-left: 20px; }

.modal-Email .table-mail .mail-date {
  padding-right: 20px; }

.modal-Email .star-mail,
.modal-Email .check-mail {
  width: 40px; }

.modal-Email .unread td a,
.modal-Email .unread td {
  font-weight: bold;
  color: inherit; }

.modal-Email .read td a,
.modal-Email .read td {
  font-weight: normal;
  color: inherit; }

.modal-Email .unread td {
  background-color: #f9f8f8; }

.modal-Email .mail-detail {
  margin-top: 10px; }
  .modal-Email .mail-detail header {
    border-bottom: 1px solid #e7eaec;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px; }
  .modal-Email .mail-detail header h5 {
    display: flex;
    justify-content: space-between; }
    .modal-Email .mail-detail header h5 p {
      margin-bottom: 0; }
  .modal-Email .mail-detail .mail-body {
    padding: 10px;
    min-height: 400px;
    text-align: justify;
    border: 1px solid lightgray; }

.modal-Email .mail-subject {
  font-size: normal; }

.task-Description {
  font-size: 1.0rem;
  text-align: justify;
  line-height: 30px; }

.task-Details {
  padding: 20px;
  text-align: justify;
  line-height: 20px; }
  .task-Details h2 {
    margin-bottom: 20px;
    font-size: 1.25rem;
    text-align: center;
    color: #9e3237; }
  .task-Details h2 small {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    color: #5378ad; }
    .task-Details h2 small .icon {
      margin-right: 10px; }
  .task-Details dl {
    padding: 2rem 1rem 0; }
  .task-Details dt,
  .task-Details dd {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e7eaec; }
    .task-Details dt:last-of-type,
    .task-Details dd:last-of-type {
      border-bottom: 0; }
    .task-Details dt .icon,
    .task-Details dd .icon {
      margin-right: 10px;
      color: #5279ae; }
  .task-Details dd ul {
    padding-left: 15px;
    margin: 0; }
