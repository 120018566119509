/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

.resource-List ul {
  padding: 0;
  list-style: none; }

.resource-List .resource-Item {
  border-bottom: 1px solid #d2d7df;
  margin-bottom: 10px;
  padding-bottom: 10px; }

.resource-Filter {
  display: flex;
  justify-content: space-between; }
  .resource-Filter .form-group,
  .resource-Filter .btn {
    margin-bottom: 0 !important; }

#button-go-to-decisions {
  margin-top: 25px;
  color: #5378ad; }

#div-resources-fixed {
  max-height: 500px;
  margin-left: 0 !important; }
  #div-resources-fixed thead th {
    position: sticky;
    top: -1px;
    z-index: 99; }
  #div-resources-fixed .col-fix--first {
    position: sticky;
    top: 0;
    left: 0; }
  #div-resources-fixed th.col-fix--first {
    z-index: 9999; }
  #div-resources-fixed .table-resource {
    display: table !important; }

#div-main-resources {
  max-height: 500px; }
